<template>
  <v-navigation-drawer v-model="drawerState" temporary>
    <v-list nav density="compact"
      v-model="group"
      active-class="light-blue--text text--accent-4"
    >
      <v-list-item to="/" exact>
        <template v-slot:prepend>
          <v-icon>{{mdiHomeOutline}}</v-icon>
        </template>
        <v-list-item-title>Accueil</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isAdmin" to="/notifications">
        <template v-slot:prepend>
          <v-icon>{{mdiBellRingOutline}}</v-icon>
        </template>
        <v-list-item-title>Notifications</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isAdmin" to="/products">
        <template v-slot:prepend>
          <v-icon>{{mdiCorn}}</v-icon>
        </template>
        <v-list-item-title>Gestion des produits</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isAdmin" to="/gicasa-mapper-management">
        <template v-slot:prepend>
          <v-icon>{{mdiLinkVariant}}</v-icon>
        </template>
        <v-list-item-title>Gestion du couplage Gicasa</v-list-item-title>
      </v-list-item>

      <v-list-item to="/print-management">
        <template v-slot:prepend>
          <v-icon>{{mdiPrinter}}</v-icon>
        </template>
        <v-list-item-title>Gestion des impressions</v-list-item-title>
      </v-list-item>

      <v-list-item to="/status">
        <template v-slot:prepend>
          <v-icon>{{mdiCheckNetworkOutline}}</v-icon>
        </template>
        <v-list-item-title>Etat du système</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isAdmin" to="/admin">
        <template v-slot:prepend>
          <v-icon>{{mdiCog}}</v-icon>
        </template>
        <v-list-item-title>Administration</v-list-item-title>
      </v-list-item>

      <v-list-item to="/about">
        <template v-slot:prepend>
          <v-icon>{{mdiInformation}}</v-icon>
        </template>
        <v-list-item-title>A propos</v-list-item-title>
      </v-list-item>

      <hr>

      <v-list-item to="/playground">
        <template v-slot:prepend>
          <v-icon>{{mdiTestTube}}</v-icon>
        </template>
        <v-list-item-title>Tests</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup>
import { useBaseStore } from '@/stores/base';
import { useUserStore } from '@/stores/user';
import { mdiHomeOutline, mdiBellRingOutline, mdiCheckNetworkOutline, mdiCog, mdiCorn, mdiInformation, mdiLinkVariant, mdiPrinter, mdiTestTube } from '@mdi/js';
import { computed, ref } from 'vue';

const baseStore = useBaseStore();
const userStore = useUserStore();

// data
const group = ref(null);

// computed
const drawerState = computed({
  get() {
    return baseStore.getDrawerState;
  },
  set(v) {
    return baseStore.toggleDrawerState(v);
  }
});

const isAdmin = computed(() => {
  const roles = userStore.getRoles;
  for (let role of roles) {
    if (role.key === 'sysop' || role.key === 'admin') {
      return true;
    }
  }
  return false;
});
</script>