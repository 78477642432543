import idsrvAuth from '@/utils/idsrvAuth';

import { createRouter, createWebHistory } from 'vue-router';

import { useMqttStore } from '@/stores/mqtt';

//import Admin from '@/views/Admin.vue';
import AuthRequired from '@/views/auth/AuthRequired.vue';
//import CellChart from '@/views/CellChart.vue';
//import CellHistory from '@/views/CellHistory.vue';
//import CellView from '@/views/CellView.vue';
//import GicasaMapperManagement from '@/views/GicasaMapperManagement.vue';
//import Home from '@/views/Home.vue';
//import NotFound from '@/views/NotFound.vue';
//import Notifications from '@/views/Notifications.vue';
//import Playground from '@/views/Playground.vue';
//import Products from '@/views/Products.vue';
//import Profile from '@/views/Profile.vue';
//import SiteView from '@/views/SiteView.vue';
//import AdminBannerView from '@/views/admin/Banner.vue';
//import AdminRBACView from '@/views/admin/RBAC.vue';
//import Permissions from '@/views/admin/rbac/Permissions.vue';
//import Roles from '@/views/admin/rbac/Roles.vue';
//import Reports from '@/views/admin/Reports.vue';
//import AdminUsersView from '@/views/admin/Users.vue';
//import WarehouseView from '@/views/WarehouseView.vue';

// lazy loading
const lazyLoad = function(view) {
  return () => import(`../views/${view}.vue`);
};

// navigation guards
const runMultipleGuards = (...guards) => async (to, from, next) => {
  for (const guard of guards) {
    const result = guard(to, from, next);
    if (result === false) return false;
  }
  //next();
};

const ifNotAuthenticated = (to, from, next) => {
  if (!idsrvAuth.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (idsrvAuth.isAuthenticated) {
    next();
  } else {
    next({
      path: '/auth-required',
      query: { // passing requested page path to redirect after login
        target: to.fullPath
      }
    });
  }
};

const subscribeMqtt = async (to, from, next) => {
  const mqttStore = useMqttStore();
  mqttStore.init();

  await mqttStore.unsubscribe(); // @todo should unsubscribe all topics

  const site = to.params.site;
  // @todo by default use warehouse 1
  const warehouse = (typeof to.params.warehouse === 'undefined' ? 1 : to.params.warehouse);

  console.log('[ROUTER] Subscribing to MQTT topic:', 'ta/' + site  + '/warehouses/' + warehouse +  '/default/realtime');

  mqttStore.subscribe('ta/' + site  + '/agents/opc-bridge/status');
  mqttStore.subscribe('ta/' + site  + '/warehouses/' + warehouse +  '/default/realtime');
  // @todo subscribe to additionnal (probably non-existants) topic
  //mqttStore.subscribe('ta/' + site  + '/warehouses/2/default/realtime');
  //mqttStore.subscribe('ta/' + site  + '/' + warehouse +  '/sv/#');
  //mqttStore.subscribe('coopta/all/opcua/devices/' + to.params.site + '-opcua-agent/status');

  //next();
};

const router = createRouter({
  // @todo do server configuration before using history mode
  // @see https://stackoverflow.com/a/51340156
  history: createWebHistory(),

  routes: [
    {
      path: '/',
      name: 'home',
      /*components: {
        default: Home
      },*/
      component: lazyLoad('Home'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: lazyLoad('Notifications'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/playground',
      name: 'playground',
      component: lazyLoad('Playground'),
      beforeEnter: ifAuthenticated,
      meta: {
        authName: idsrvAuth.authName
      }
    },
    {
      path: '/products',
      name: 'products',
      component: lazyLoad('Products'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/gicasa-mapper-management',
      name: 'gicasa-mapper-management',
      component: lazyLoad('GicasaMapperManagement'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/auth-required',
      name: 'auth-required',
      components: {
        default: AuthRequired
      },
      //beforeEnter: ifNotAuthenticated
    },
    {
      path: '/profile',
      name: 'profile',
      component: lazyLoad('Profile'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
      path: '/admin',
      name: 'admin',
      component: lazyLoad('Admin'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/admin/banner',
      name: 'admin-banner',
      //component: lazyLoad('admin/Banner'),
      component: () => import('../views/admin/Banner.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/admin/printers',
      name: 'admin-printers',
      //component: lazyLoad('admin/Printers'),
      component: () => import('../views/admin/Printers.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/admin/rbac',
      name: 'rbac',
      //component: lazyLoad('admin/RBAC'),
      component: () => import('../views/admin/RBAC.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/admin/rbac/roles',
      name: 'roles',
      //component: lazyLoad('admin/rbac/Roles'),
      component: () => import('../views/admin/rbac/Roles.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/admin/rbac/permissions',
      name: 'permissions',
      //component: lazyLoad('admin/rbac/Permissions'),
      component: () => import('../views/admin/rbac/Permissions.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/admin/reports',
      name: 'reports',
      //component: lazyLoad('admin/Reports'),
      component: () => import('../views/admin/Reports.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/admin/users',
      name: 'admin-users',
      //component: lazyLoad('admin/Users'),
      component: () => import('../views/admin/Users.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/admin/user-logs',
      name: 'admin-user-logs',
      //component: lazyLoad('admin/UserLogs'),
      component: () => import('../views/admin/UserLogs.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/:site(\\d+)',
      name: 'site-view',
      component: lazyLoad('SiteView'),
      beforeEnter: runMultipleGuards(ifAuthenticated, subscribeMqtt)
    },
    {
      path: '/:site(\\d+)/reports',
      name: 'site-reports-view',
      component: lazyLoad('SiteReportsView'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/:site(\\d+)/:warehouse(\\d+)/cooling',
      name: 'cooling-view',
      component: lazyLoad('Cooling'),
      beforeEnter: runMultipleGuards(ifAuthenticated, subscribeMqtt)
    },
    {
      path: '/:site(\\d+)/:warehouse(\\d+)',
      name: 'warehouse-view',
      component: lazyLoad('WarehouseView'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/:site(\\d+)/:warehouse(\\d+)/:cell(\\d+)',
      name: 'cell-view',
      component: lazyLoad('CellView'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/:site(\\d+)/:warehouse(\\d+)/:cell(\\d+)/chart',
      name: 'cell-chart',
      component: lazyLoad('CellChart'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/:site(\\d+)/:warehouse(\\d+)/:cell(\\d+)/history',
      name: 'cell-history',
      component: lazyLoad('CellHistory'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/:site(\\d+)/:warehouse(\\d+)/:cell(\\d+)/viewer',
      name: 'cell-viewer',
      //component: lazyLoad('Cell3dViewer'),
      component: () => import('../views/Cell3dViewer.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/:site(\\d+)/:warehouse(\\d+)/:cell(\\d+)/faulty-sensors',
      name: 'cell-faulty-sensors',
      //component: lazyLoad('CellFaultySensors'),
      component: () => import('../views/admin/cell/FaultySensors.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/:site(\\d+)/:warehouse(\\d+)/:cell(\\d+)/buried-sensors',
      name: 'cell-buried-sensors',
      //component: lazyLoad('CellBuriedSensors'),
      component: () => import('../views/admin/cell/BuriedSensors.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/:site(\\d+)/:warehouse(\\d+)/:cell(\\d+)/sensors-calibration',
      name: 'cell-sensors-calibration',
      //component: lazyLoad('CellSensorsCalibration'),
      component: () => import('../views/admin/cell/SensorsCalibration.vue'),
      beforeEnter: ifAuthenticated
    }/*,
    { 
      name: 'not-found',
      path: '/:catchAll(.*)', 
      component: NotFound
    }*/
  ]
});

idsrvAuth.useRouter(router);

export default router;