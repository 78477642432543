import http from '@/utils/http';

class RootDataService {
  get() {
    return http.get('/info/banner');
  }

  create(data) {
    return http.post('/info/banner', data);
  }

  delete(id) {
    return http.delete(`/info/banner/${id}`);
  }
}

export default new RootDataService();