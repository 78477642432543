<template>
  <!--<v-snackbar
    v-model="snackbar"
    timeout="2000"
    app
    fixed
    top
  >
    Snackbar message
    <template v-slot:action="{ attrs }">
      <v-btn
        color="blue"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Fermer
      </v-btn>
    </template>
  </v-snackbar>-->
  <v-snackbar
    v-model="loading"
    location="bottom"
    timeout="1500"
  >
    {{text}}
    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        @click="loading = false"
        color="blue"
      >
        Fermer
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { useBaseStore } from '@/stores/base';
import { computed } from 'vue';

const baseStore = useBaseStore();

// computed
const loading = computed({
  get() {
    return baseStore.getSnackbarLoadingState;
  },
  set(v) {
    return baseStore.setSnackbarLoadingState(v);
  }
});

const text = computed(() => {
  return baseStore.getSnackbarText;
});
</script>