import { createApp } from 'vue';
import App from '@/App.vue';
//import matomo from '@/plugins/matomo';
import { createPinia } from 'pinia';
import router from '@/router';
import { vuetify } from '@/plugins/vuetify';
import idsrvAuth from '@/utils/idsrvAuth';
import { loadFonts } from '@/plugins/webfontloader';

loadFonts();

//Vue.config.productionTip = false;
  
//Vue.config.devtools = false
//Vue.config.debug = false
//Vue.config.silent = true

idsrvAuth.startup().then(ok => {
  console.log('Startup initialization (idsrvAuth)');

  if (ok) {
    const app = createApp(App);
    app.config.globalProperties.$oidc = idsrvAuth;

    app.use(createPinia());
    app.use(vuetify);
    app.use(router);

    app.mount('#app');
  } else {
    console.log('Startup was not ok');
  }
}).catch(function() {
  console.log('Startup was not ok (idsrvAuth)');
});