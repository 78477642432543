<template>
  <v-container>
    <h3>Authentification</h3>

    <template v-if="isAuthenticated">
      <p>Vous êtes déjà connecté...</p>
    </template>
    <template v-else>
      <p>Connection requise. Vous allez être redirigé vers <span class="font-italic">"connect.terreatlantique.com"</span> pour vous authentifier.</p>

      <v-btn block class="mt-3" color="primary" @click="login">
        Se connecter
        <v-icon>{{mdiLoginVariant}}</v-icon>
      </v-btn>
    </template>
  </v-container>
</template>

<script setup>
import idsrvAuth from '@/utils/idsrvAuth';
import { mdiLoginVariant } from '@mdi/js';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const breadcrumbsStore = useBreadcrumbsStore();
const route = useRoute();
const router = useRouter();

// computed
const isAuthenticated = computed(() => {
  return idsrvAuth.isAuthenticated;
});

// mounted
onMounted(() => {
  breadcrumbsStore.setBreadcrumbs([
    {
      text: 'Accueil',
      disabled: false,
      href: router.resolve('/').href
    },
    {
      text: 'Authentification',
      disabled: true
    }
  ]);
});

// methods
const login = function() {
  // authenticate through OIDC flow,
  // then return to target page after authentication
  // @see https://github.com/soukoku/vue-oidc-client/blob/dbfb124b1e4016c7d44a877b7a3bcde2c26a8ebf/vue2/src/vue-oidc-client.ts#L303
  idsrvAuth.signIn({
    state: {
      to: route.query.target
    }
  });
};
</script>