import { defineStore } from 'pinia';
import RootDataService from '@/services/RootDataService';

export const useBaseStore = defineStore({
  id: 'base',

  state: () => ({
    context: {
      site: null,
      warehouse: null,
      cell: null,
      probe: null,
      sensor: null,
      weather: null
    },
    drawerState: false,
    loadingState: false,
    snackbarLoadingState: false,
    snackbarText: '',
    globalMessage: null
  }),

  getters: {
    getContext(state) {
      return state.context;
    },
    getDrawerState(state) {
      return state.drawerState;
    },
    getGlobalMessage(state) {
      return state.globalMessage;
    },
    getLoadingState(state) {
      return state.loadingState;
    },
    getSnackbarLoadingState(state) {
      return state.snackbarLoadingState;
    },
    getSnackbarText(state) {
      return state.snackbarText;
    }
  },

  actions: {
    deleteGlobalMessage() {
      // @todo this.globalMessage.id is undefined if banner is not set
      const promise = RootDataService.delete(this.globalMessage.id)
        .then((response) => {
          // update state after a successfully save op
          this.globalMessage = response.data.data;
        });

      return promise;
    },
    async fetchGlobalMessage() {
      console.log('[base] retrieve global message from server');
      // get message from server
      let banner = null;

      try {
        const response = await RootDataService.get();
        banner = response.data.data;
      } catch(err) {
        return err; // @todo
      }

      this.globalMessage = banner;

      return banner;
    },
    setGlobalMessage(data) {
      const promise = RootDataService.create(data)
        .then((response) => {
          // update state after a successfully save op
          this.globalMessage = response.data.data;
        });

      return promise;
    },
    setContext(data) {
      this.context = data;
    },
    setLoadingState(data) {
      this.loadingState = data;
    },
    setSnackbarLoadingState(data) {
      this.snackbarLoadingState = data;
    },
    setSnackbarText(data) {
      this.snackbarText = data;
    },
    toggleDrawerState(data) {
      this.drawerState = data;
    }
  }
});