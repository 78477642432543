import { defineStore } from 'pinia';
import MqttService from '@/services/MqttService';

let mqtt = null;

export const useMqttStore = defineStore({
  id: 'mqtt',

  state: () => ({
    remoteClientStatus: null,
    topics: [],
    topicData: {},
    connectionStatus: 'disconnected'
  }),

  actions: {
    // must be called before using the store!
    // subsequent calls will be ignored if necessary
    init() {
      //console.log('[MQTT-STORE] Will initialize if necessary');

      if (!mqtt) {
        //console.log('[MQTT-STORE] Initializing');
        const updateStatusCallback = (status) => {
          this.setStatus(status);
        };
  
        mqtt = new MqttService(updateStatusCallback);
      }
    },

    setStatus(status) {
      this.connectionStatus = status;
    },

    async subscribe(topic) {
      this.topics.push(topic); // @todo push if not exist!
      // @todo subscribe by key/topic
      await mqtt.subscribe(topic, (topic, message) => {
        if (topic.endsWith('agents/opc-bridge/status')) {
          this.remoteClientStatus = message;
        } else {
          this.topicData = message;
        }
      });
    },

    async unsubscribe(topic) {
      if (typeof topic === 'undefined' || topic === null) {
        for(const element of this.topics) {
          await mqtt.unsubscribe(element);
        }
        this.topics = [];
      } else {
        await mqtt.unsubscribe(topic);
        this.topics = this.topics.filter(element => element !== topic);
      }

      // @todo unsubscribe by key
      // @todo unsubscribe all topics if key is null or undefined
      /*await mqtt.unsubscribe(topic);*/
      //delete this.topicData;
      this.topicData = {};
    }
  }
});
