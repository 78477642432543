import http from '@/utils/http';

class AdminUserDataService {
  getAll(data) {
    return http.get('/admin/users', { params: data });
  }

  get(id) {
    return http.get(`/admin/users/${id}`);
  }

  create(data) {
    return http.post('/admin/users', data);
  }

  update(id, data) {
    return http.put(`/admin/users/${id}`, data);
  }

  delete(id) {
    return http.delete(`/admin/users/${id}`);
  }

  findByEmail(email) {
    return http.get(`/admin/users?email=${email}`);
  }

  updateUserProfile(user_id, data) {
    return http.put(`/admin/users/${user_id}/profile`, data);
  }

  addRole(user_id, data) {
    return http.post(`/admin/users/${user_id}/roles`, data);
  }

  removeRole(user_id, role_id) {
    return http.delete(`/admin/users/${user_id}/roles/${role_id}`);
  }

  addSite(user_id, data) {
    return http.post(`/admin/users/${user_id}/sites`, data);
  }

  removeSite(user_id, site_id) {
    return http.delete(`/admin/users/${user_id}/sites/${site_id}`);
  }
}

export default new AdminUserDataService();