<template>
  <v-dialog
    :fullscreen="$vuetify.display.xs"
    v-model="dialog"
    max-width="500"
  >
    <v-card>
      <v-toolbar
        v-if="$vuetify.display.xs"
        color="primary"
      >
        <v-btn
          color="white"
          icon
          @click="close"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>

        <v-toolbar-title class="text-white">Modification de la bannière</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          color="white"
          @click="save"
        >
          Enregistrer
        </v-btn>
      </v-toolbar>

      <v-card-title v-if="$vuetify.display.smAndUp">
        <span>Modification de la bannière</span>
      </v-card-title>

      <v-card-text>
        La bannière est visible par tous les utilisateurs.
        Elle peut être cachée temporairement par les utilisateurs mais sera de nouveau affichée
        à chaque rechargement de l'application (F5, ouverture de l'adresse via un lien externe etc...).
      </v-card-text>

      <v-card-text class="pa-0">
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="title"
                  label="Titre"
                  name="title"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea
                  label="Contenu"
                  name="content"
                  v-model="content"
                  rows="3"
                  auto-grow
                  clearable
                  counter
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-divider v-if="$vuetify.display.smAndUp"></v-divider>

      <v-card-actions v-if="$vuetify.display.smAndUp">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          @click="close"
        >
          Annuler
        </v-btn>
        <v-btn
          color="primary"
          @click="save"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import logger from '@/utils/logger';
import { useBaseStore } from '@/stores/base';
import { mdiClose } from '@mdi/js';
import { computed, onMounted, ref } from 'vue';

const baseStore = useBaseStore();

// events
const emit = defineEmits(['close', 'update:value']);

// props
const props = defineProps({
  banner: {
    type: Object,
    required: true
  },
  value: Boolean
});

// computed
const dialog = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit('update:value', value);
  }
});

// data
const form = ref(null);
const title = ref('');
const content = ref('');
const loading = ref(true);
const valid = ref(true);

// mounted
onMounted(() => {
  title.value = props.banner.title;
  content.value = props.banner.content;
});

// methods
const save = function() {
  const updatedData = {
    title: title.value,
    content: content.value
  };

  // merge orignal data with form data
  let data = Object.assign({}, props.banner, updatedData);

  // save data (on the server)
  baseStore.setGlobalMessage(data)
    .then(() => {
      close();
    })
    .catch((err) => {
      // @todo factorize

      // validation error
      const formChildren = form.value.$children;
      // @todo handle form validation
      logger.log(err);
      const errors = err.response.data.error.validation;
      logger.log('errors from server:', errors);

      for (let component of formChildren) {
        if (Object.prototype.hasOwnProperty.call(component, '$_modelEvent') && component.$_modelEvent === 'input') {
          // skip component without 'name' attribute
          if (!Object.prototype.hasOwnProperty.call(component.$attrs, 'name'))
            continue;

          // clear previous errors
          //component.errorMessages = [];
          component.errorBucket = [];

          // skip component without error
          if (!Object.prototype.hasOwnProperty.call(errors, component.$attrs.name))
            continue;

          const errorsArray = errors[component.$attrs.name].map((error) => {
            return error.message;
          });
          //component.errorMessages = errorsArray;
          component.errorBucket = errorsArray;

          continue;
        }
      }
    });
};

const resetValidation = function() {
  form.value.resetValidation();
};

const close = function() {
  emit('close');
};
</script>