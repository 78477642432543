<template>
  <v-app>
    <v-app-bar
      color="green"
      extension-height="0"
      scroll-behaviour="hide"
      theme="dark"
    >
      <v-app-bar-nav-icon @click="drawerState = !drawerState"></v-app-bar-nav-icon>

      <div class="d-flex align-center">
        <v-img
          alt="Logo Terre Atlantique"
          class="shrink mr-2"
          height="45"
          :src="logoUrl"
          transition="scale-transition"
          width="110"
        />
      </div>

      <v-toolbar-title class="d-none d-sm-flex">Thermométrie</v-toolbar-title>
      
      <v-spacer></v-spacer>

      <!-- night mode button -->
      <v-btn icon @click="toggleTheme">
        <v-icon>{{themeButtonIcon}}</v-icon>
      </v-btn>

      <!-- notification bell -->
      <NotificationBell />

      <!-- user menu button -->
      <UserMenu />

      <!-- slot:extension -->
      <template v-slot:extension>
        <!-- snackbar -->
        <AppSnackbar/>

        <!-- loader -->
        <AppProgressBar/>
      </template>
    </v-app-bar>

    <!-- navigation drawer -->
    <NavigationSidebar/>

    <v-main>
      <!-- breadcrumbs -->
      <breadcrumbs-toolbar/>

      <!-- banner: global message -->
      <app-banner/>

      <!-- router -->
      <router-view/>
    </v-main>
  </v-app>
</template>

<script setup>
import AppBanner from '@/components/App/Banner.vue';
import AppProgressBar from '@/components/AppProgressBar.vue';
import AppSnackbar from '@/components/AppSnackbar.vue';
import BreadcrumbsToolbar from '@/components/BreadcrumbsToolbar.vue';
import NavigationSidebar from '@/components/NavigationSidebar.vue';
import NotificationBell from '@/components/NotificationBell.vue';
import UserMenu from '@/components/UserMenu.vue';
import logoUrl from '@/assets/logo.svg';
import idsrvAuth from '@/utils/idsrvAuth';
import { useTheme } from 'vuetify';
import { useBaseStore } from '@/stores/base';
import { useUserStore } from '@/stores/user';
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js';
import { computed, ref } from 'vue';

const baseStore = useBaseStore();
const userStore = useUserStore();
const theme = useTheme();

// data
const loading = ref(false);
const loadingSnackbar = ref(false);
const snackbarText = ref('Chargement...');

// computed
const themeButtonIcon = computed(() => {
  return !theme.global.current.value.dark ? mdiWeatherNight : mdiWeatherSunny;
});

const drawerState = computed({
  get() {
    return baseStore.getDrawerState;
  },
  set(value) {
    return baseStore.toggleDrawerState(value);
  }
});

// already authenticated
if (idsrvAuth.isAuthenticated) {
  // load user roles
  userStore.fetchRoles();
} else {
  // wait user authentication
  idsrvAuth.events.addUserLoaded((user) => {
    // load user roles
    userStore.fetchRoles();
  });
}

// methods
const toggleTheme = function() {
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark';
};
</script>

<style>
/* fix progress bar @see https://medium.com/js-dojo/how-to-visualize-application-loading-state-in-vuetify-44f0f0242094 */
.v-toolbar__extension {
  padding: 2px 0 0 0 !important;
}

/* main background */
.v-theme--light .v-main {
  background: linen;
}

/* anchors: override default color */
.v-container a:not(.v-btn), .v-container a:not(.v-btn):visited {
  color: rgba(var(--v-theme-primary))
}
</style>
