import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import { fr } from 'vuetify/locale';

export const vuetify =  createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  locale: {
    locale: 'fr',
    messages: { fr }
  },
  display: {
    mobileBreakpoint: 'md'
  }
  /*defaults: {
    VMenu: {
      VBtn: {
        color: 'primary',
        variant: 'elevated'
      }
    },
    VCardActions: {
      VBtn: {
        color: 'primary',
        variant: 'elevated'
      }
    },
    VBtn: {
      color: 'primary',
      variant: 'elevated'
    }
  },*/
  /*theme: {
    defaultTheme: 'customLight',
    themes: {
      customLight: {
        colors: {
          'background': '#faf0e6',
          'surface': '#FFFFFF',
          'primary': '#1976D2',
          'secondary': '#5CBBF6',
          'error': '#FF5252',
          'info': '#2196F3',
          'success': '#4CAF50',
          'warning': '#FB8C00',
          'on-background': '#212121',
          'on-surface': '#212121'
        },
      },
      customDark: {
        colors: {
          primary: '#2196F3',
          secondary: '#424242',
          accent: '#FF4081',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00'
        }
      }
    }
  }*/
});