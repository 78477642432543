<template>
  <v-banner
    v-if="banner"
    bg-color="blue-lighten-4"
    color="blue-lighten-1"
    :icon="mdiInformation"
    stacked
  >
    <h3 v-if="banner.title">{{banner.title}}</h3>
    <p>{{banner.content}}</p>

    <template v-slot:actions>
      <!--<v-btn
        @click="hide"
        color="primary"
      >
        Cacher
      </v-btn>-->

      <v-btn
        v-if="hasPermission('banner:delete')"
        class="mx-2"
        @click="deleteBanner"
        color="error"
        variant="flat"
      >
        Supprimer
      </v-btn>

      <v-btn
        v-if="hasPermission('banner:create')"
        class="mx-2"
        @click="openEditDialog"
        color="primary"
        variant="flat"
      >
        Modifier
      </v-btn>
    </template>

    <!-- dialog -->
    <banner-dialog
      v-if="dialog"
      @close="close"
      :banner="tmpData"
      :value="dialog"
    />
  </v-banner>
</template>

<script setup>
import { useBaseStore } from '@/stores/base';
import { useUserStore } from '@/stores/user';
import BannerDialog from '@/components/App/BannerDialog.vue';
import idsrvAuth from '@/utils/idsrvAuth';
import logger from '@/utils/logger';
import { mdiInformation } from '@mdi/js';
import { computed, ref, watch } from 'vue';

const baseStore = useBaseStore();
const userStore = useUserStore();

// wait user authentication
/*userStore.$subscribe((mutation, state) => {
  logger.log(mutation);
  if (state.profile)
    this.baseStore.fetchGlobalMessage();
});
//}, { detached: true });*/

// wait user authentication
idsrvAuth.userLoaded().then(() => {
  // load banner message
  baseStore.fetchGlobalMessage();
}).catch((e) => {
  logger.log(e);
});

// data
const dialog = ref(false);
const tmpData = ref(null);

// computed
const banner = computed({
  get() {
    return baseStore.getGlobalMessage;
  },
  set(v) {
    return baseStore.setGlobalMessage(v);
  }
});

// watch
watch(
  dialog,
  (visible) => {
    visible || close();
  }
);

// methods
const deleteBanner = function() {
  baseStore.deleteGlobalMessage()
    .catch((err) => {
      // @todo show error
      logger.log(err);
    });
};

const openEditDialog = function() {
  tmpData.value = Object.assign({}, banner.value);
  dialog.value = true;
};

const close = function() {
  dialog.value = false;
};

const hasPermission = function(permission) {
  const permissions = userStore.getPermissions;

  if (permissions.some(e => e.key === permission)) {
    // 'permissions' array contains an element with 'key' property
    // set to 'permission'
    return true;
  }

  return false;
};
</script>

<style scoped>
:deep(.v-banner__content) {
  display: block;
}
:deep(.v-banner-actions) {
  margin-top: 0 !important;
}
</style>