<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      location="bottom end"
      origin="auto"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          icon
        >
          <v-avatar v-if="getProfile.name" color="primary" size="36">
            <span class="text-white text-subtitle-2">{{ getUserInitials }}</span>
          </v-avatar>
          <v-avatar v-else color="primary" size="36">
            <v-icon dark><!-- mdi-account-circle -->
              {{mdiLoginVariant}}
            </v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-card theme="light">
        <v-list>
          <v-list-item>
            <template v-slot:prepend v-if="getProfile.name">
              <v-avatar color="primary">
                <!--<v-img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John"></v-img>-->
                <span class="white-text text-subtitle-2">{{ getUserInitials }}</span>
              </v-avatar>
            </template>

            <template v-if="getProfile.name">
              <v-list-item-title>{{ getProfile.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ getProfile.email }}</v-list-item-subtitle>
            </template>
            <template v-else>
              <v-list-item-title>Déconnecté</v-list-item-title>
            </template>

            <!--<v-list-item-action>
              <v-btn
                :class="fav ? 'red--text' : ''"
                icon
                @click="fav = !fav"
              >
                <v-icon>mdi-heart</v-icon>
              </v-btn>
            </v-list-item-action>-->
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list density="compact">
          <v-list-item v-if="isAuthenticated" @click="profile">
            <template v-slot:prepend>
              <v-icon>{{mdiAccount}}</v-icon>
            </template>
            <v-list-item-title>Profil</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="isAuthenticated" @click="logout">
            <template v-slot:prepend>
              <v-icon>{{mdiLogout}}</v-icon>
            </template>
            <v-list-item-title>Se déconnecter</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!isAuthenticated" @click="login">
            <template v-slot:prepend>
              <v-icon>{{mdiLogin}}</v-icon>
            </template>
            <v-list-item-title>Se connecter</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!isAuthenticated" @click="register">
            <template v-slot:prepend>
              <v-icon>{{mdiAccountPlus}}</v-icon>
            </template>
            <v-list-item-title>S'enregistrer</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="menu = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import idsrvAuth from '@/utils/idsrvAuth';
import logger from '@/utils/logger';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { mdiAccount, mdiAccountPlus, mdiLogin, mdiLoginVariant, mdiLogout } from '@mdi/js';

const route = useRoute();
const router = useRouter();

// data
//const fav = ref(true);
const menu = ref(false);

// computed
const user = computed(() => {
  return {
    ...idsrvAuth.userProfile,
    accessToken: idsrvAuth.accessToken
  };
});

const claims = computed(() => {
  if (user.value) {
    return Object.keys(user.value).map(key => ({
      key,
      value: user.value[key]
    }));
  }
  return [];
});

const isAuthenticated = computed(() => {
  logger.log(user.value);
  return idsrvAuth.isAuthenticated;
});

const getProfile = computed(() => {
  return idsrvAuth.userProfile;
});

const getUserInitials = computed(() => {
  let initials = idsrvAuth.userProfile.name;
  if (initials) return initials.substring(0, 2).toUpperCase();
  return 'NO';
});

// methods
const login = function() {
  // authenticate through OIDC flow,
  // then return to target page after authentication
  // @see https://github.com/soukoku/vue-oidc-client/blob/dbfb124b1e4016c7d44a877b7a3bcde2c26a8ebf/vue2/src/vue-oidc-client.ts#L303
  idsrvAuth.signIn({
    state: {
      to: route.query.target
    }
  });

  // close menu
  menu.value = false;
};

const logout = function() {
  idsrvAuth.signOut()
    .then(function() {
      logger.log('sign-out successfully');
    })
    .catch(function(err) {
      logger.error('backend op does not provide end_session_endpoint in its /.well-known/openid-configuration');
    })
    .finally(function() {
      // reload page
      router.go(0);
      // @see https://stackoverflow.com/a/47005895
      // window.location.reload();
    });

  menu.value = false; // close menu
};

const profile = function() {
  // if we're not already on the profile page
  if (route.path != '/profile') {
    router.push('/profile');
  }
  menu.value = false; // close menu
};

const register = function() {
  window.location.href = 'https://connect.terreatlantique.com/#/register';
};
</script>