<template>
  <div v-if="isAuthenticated" class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      location="bottom end"
      origin="auto"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          icon
        >
          <v-badge
            color="error"

            :content="nofNotifications"
            :value="nofNotifications"
          >
            <v-icon>{{mdiBellRingOutline}}</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-card max-width="500" theme="light">
        <v-card-title>Notifications (test uniquement)</v-card-title>
        <v-list lines="three"><!-- dense -->
          <v-list-item value="1">
            <v-list-item-title><span class="text-primary">La Rochelle</span> &mdash; Alerte température dépassée</v-list-item-title>
            <v-list-item-subtitle>Température supérieure à 20°C, sonde X, magasin Y, cellule C1</v-list-item-subtitle>
            <v-list-item-subtitle class="notif-datetime">Il y a 6 minutes</v-list-item-subtitle>
            <template v-slot:append>
              <v-btn
                :class="fav ? 'red-text' : ''"
                icon
                variant="text"
                @click="fav = !fav"
              >
                <v-icon color="red">{{mdiWindowClose}}</v-icon>
              </v-btn>
            </template>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item value="2">
            <v-list-item-title><span class="text-primary">Loiré</span> &mdash; Alerte température dépassée</v-list-item-title>
            <v-list-item-subtitle>Température supérieure à 20°C, sonde X, magasin Y, cellule C12</v-list-item-subtitle>
            <v-list-item-subtitle class="notif-datetime">Il y a 8 minutes</v-list-item-subtitle>
            <template v-slot:append>
              <v-btn
                :class="fav ? 'red-text' : ''"
                icon
                variant="text"
                @click="fav = !fav"
              >
                <v-icon color="red">{{mdiWindowClose}}</v-icon>
              </v-btn>
            </template>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="menu = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { mdiBellRingOutline, mdiWindowClose } from '@mdi/js';
import idsrvAuth from '@/utils/idsrvAuth';
import { computed, ref } from 'vue';

// data
const fav = ref(true);
const menu = ref(false);
const nofNotifications = ref(2);

// computed
const isAuthenticated = computed(() => {
  return idsrvAuth.isAuthenticated;
});
</script>

<style scoped>
.v-card .v-list {
  padding: 0;
}
.notif-datetime {
  font-style: italic;
  margin-top: 7px;
  text-align: right;
}
</style>