import { defineStore } from 'pinia';

export const useBreadcrumbsStore = defineStore({
  id: 'breadcrumbs',

  state: () => ({
    breadcrumbs: [{
      text: 'Accueil',
      disabled: true,
      href: '/'
    }]
  }),

  getters: {
    getBreadcrumbs(state) {
      return state.breadcrumbs;
    }
  },

  actions: {
    setBreadcrumbs(data) {
      this.breadcrumbs = data;
    }
  }
});