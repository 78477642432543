<template>
  <v-toolbar density="compact" elevation="4" class="ta-breadcrumbs">
    <v-breadcrumbs density="compact" :items="breadcrumbsStore.breadcrumbs">
      <template v-slot:divider>
        <v-icon>{{mdiChevronRight}}</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="getPathFromItem(item)"
          :disabled="item.disabled"
          exact
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <v-spacer></v-spacer>

  </v-toolbar>
</template>

<script setup>
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { mdiChevronRight } from '@mdi/js';

const breadcrumbsStore = useBreadcrumbsStore();

// methods
const getPathFromItem = function(item) {
  const href = (typeof item.href === 'undefined') ? '' : item.href;

  return {
    path: href
  };
};
</script>

<style scoped>
.ta-breadcrumbs .v-breadcrumbs--density-compact {
  padding-top: 10px;
}
</style>